.navbar {
  .logo {
    display: block;
    margin-top: 14px;
    height: 36px;
    width: 168px;
    background : url(/img/logo-light.png);
  }  
}

@media (max-width: $grid-float-breakpoint) {
  .navbar {
    .logo {
      margin-top: 8px;
      margin-left: 15px;
      height: 34px;
      width: 158px;
      background : url(/img/logo-dark.png) no-repeat;
      background-size: 158px 34px;
    }
  }
}

@media (min-width: $grid-float-breakpoint) {
  .navbar-default {
    padding: 10px 0;
    background-color: transparent;
    border-color: transparent;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }
}

@media (min-width: $grid-float-breakpoint) {
  .navbar-default.navbar-shrink {
    padding: 0;
    background-color: #fff;
    .logo {
      background : url(/img/logo-dark.png);
    }
  }
}
