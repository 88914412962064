.p-20 { padding: 20px; }
.pt-20 { padding-top: 20px; }
.pr-20 { padding-right: 20px; }
.pb-20 { padding-bottom: 20px; }
.pl-20 { padding-left: 20px; }

.p-15 { padding: 15px; }
.pt-15 { padding-top: 15px; }
.pr-15 { padding-right: 15px; }
.pb-15 { padding-bottom: 15px; }
.pl-15 { padding-left: 15px; }

.p-10 { padding: 10px; }
.pt-10 { padding-top: 10px; }
.pr-10 { padding-right: 10px; }
.pb-10 { padding-bottom: 10px; }
.pl-10 { padding-left: 10px; }

.p-5 { padding: 5px; }
.pt-5 { padding-top: 5px; }
.pr-5 { padding-right: 5px; }
.pb-5 { padding-bottom: 5px; }
.pl-5 { padding-left: 5px; }

.p-none { padding: 0; }
.pt-none { padding-top: 0; }
.pr-none { padding-right: 0; }
.pb-none { padding-bottom: 0; }
.pl-none { padding-left: 0; }

.m-20 { margin: 20px; }
.mt-20 { margin-top: 20px; }
.mr-20 { margin-right: 20px; }
.mb-20 { margin-bottom: 20px; }
.ml-20 { margin-left: 20px; }

.m-15 { margin: 15px; }
.mt-15 { margin-top: 15px; }
.mr-15 { margin-right: 15px; }
.mb-15 { margin-bottom: 15px; }
.ml-15 { margin-left: 15px; }

.m-10 { margin: 10px; }
.mt-10 { margin-top: 10px; }
.mr-10 { margin-right: 10px; }
.mb-10 { margin-bottom: 10px; }
.ml-10 { margin-left: 10px; }

.m-5 { margin: 5px; }
.mt-5 { margin-top: 5px; }
.mr-5 { margin-right: 5px; }
.mb-5 { margin-bottom: 5px; }
.ml-5 { margin-left: 5px; }

.m-none { margin: 0; }
.mt-none { margin-top: 0; }
.mr-none { margin-right: 0; }
.mb-none { margin-bottom: 0; }
.ml-none { margin-left: 0; }
