header {
  position: relative;
}

header .container {
    position: relative;
    z-index: 10;
    color: #fff;
}

header .intro {
  padding-top: 165px;
  padding-bottom: 0;
}

header .btn {
  margin: 20px 0 80px 0;
}

header .dashboard {
  display: block;
  img {
    max-width: 90%;
  }
}

#header-bg {
  background-image: url(/img/welcome.jpg);
  background-attachment: fixed;
  background-size: cover;
  position: absolute;
  background-position: center;
  width: 100%;
  height: 100%;
  top: 0;
}

@media (max-width: $grid-float-breakpoint) {
  header .intro {
    padding: 125px 20px 0 20px;
  }
  header h1 {
    font-size: 30px;
  }
  header p {
    font-size: 15px;    
  }
}